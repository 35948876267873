//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-264:_940,_8300,_8680,_9120,_9044,_6892,_7828,_9516;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-264')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-264', "_940,_8300,_8680,_9120,_9044,_6892,_7828,_9516");
        }
      }catch (ex) {
        console.error(ex);
      }