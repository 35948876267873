const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/brands';

function getFitmentSearchTitle() {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = document.querySelector('.breadcrumb .item.is-active')?.textContent.trim();
  const pageHeading = document.querySelector('body.template-collection h1.page-heading');
  return pageHeading
    ? ''
    : pageType === 'collection'
      ? title
      : pageType === 'vendors'
        ? `${title} Parts`
        : 'Parts';
}

const updateCallback = () => {
  window.theme.wishlist.init();

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm_brands')) {
    const searchResultWrapper = document.querySelector('.page-container .rte');
    searchResultWrapper.classList.remove('rte');
  }
}

export default {
  platform: 'shopify',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: categorySelectionPageUrl,
        field: window.Convermax.config?.categorySelectionPageField || 'category',
      },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
    getFitmentSearchTitle,
    onVehicleSelected: () => window.document.querySelector('#site-nav-mobile .close_menu')?.click(),
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-collection .page-collections',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: '.halo-header-PC .header-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.halo-header-mobile .item--searchMobile',
        class: 'navUser-action items',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'header.site-header',
        class: 'cm_vehicle-widget__header_container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'Garage__layout-1',
      type: 'Garage',
      location: {
        insertBefore: '.halo-header-PC .item .item--cart',
        class: 'item cm_garage-container cm_layout-1',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'Garage__layout-2',
      type: 'Garage',
      location: {
        insertBefore: '.halo-header-PC .item.item--cart',
        class: 'item navUser-action custom-2 cm_garage-container cm_layout-2',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        insertAfter: '.site-nav-mobile.two .item:nth-child(2)',
        wrapper: 'li',
        class: 'menu-lv-1 item',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.template-product .product-form__item' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tab-horizontal .tab:first-child',
        class: 'nav-item tab',
        wrapper: 'li',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tab-content .tab-description',
        class: 'tab-content tab-pane fade',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.template-list-collections .page-collections',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brand-list, #cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
