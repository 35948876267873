//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-264:_2160,_168,_6548,_8916,_5984,_7840,_4200,_8928;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-264')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-264', "_2160,_168,_6548,_8916,_5984,_7840,_4200,_8928");
        }
      }catch (ex) {
        console.error(ex);
      }